<template>
  <div>
    <vx-card class="mt-8">
      <div class="card-title justify-between">
        <h2 class="w-auto mb-2">Consumer (+Person) Reseller Detail Report</h2>
        <v-select
          class="min-180 mb-2"
          v-model="monthYearReport"
          :options="monthYearData"
          label="Value"
          :reduce="option => option.Key"
          :clearable="false"
          @input="getConsumerResellerDetailReport()"
        />
      </div>
      <div class="review-location">
        <div class="vx-row">
          <div class="vx-col w-full">
              <p>This report only shows a summary of customers with a consumer plan and have identified at lease one unique visitor.</p>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- Consumer Reseller Detail Report Table -->
    <vx-card class="mt-8">
      <div class="common-table">
        <div class="table-row-select">
          <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in recordList"
            />
          </vs-select>
        </div>
        <div class="actions-button activity-report-btn">              
              <vx-tooltip color="actionbutton" text="EXCEL">
                <vs-button class="bg-actionbutton" type="filled">
                    <download-excel
                      class="btn btn-default"
                      :data="excelData"
                      :exportFields="excelFields"
                      worksheet="Consumer Reseller Detail Report"
                      name="Consumer Reseller Detail Report.xls"
                    >
                      <img src="@/assets/images/excel.svg" />
                    </download-excel>
                </vs-button>
              </vx-tooltip>
        </div>
        <vs-table id="consumerResellerDetailReport" ref="tableData" :data="consumerResellerDetailReportData" :max-items="perPage" pagination search>
          <template slot="thead">
            <vs-th sort-key="Reseller">Reseller</vs-th>
            <vs-th sort-key="Customer">Customer</vs-th>
            <vs-th sort-key="Plan_Type">Plan Type</vs-th>
            <vs-th sort-key="Cookie_Banner">Cookie Banner</vs-th>
            <vs-th sort-key="Max_Identifications">Max</vs-th>
            <vs-th sort-key="Cost_Per_Identifications">Unit Cost</vs-th>
            <vs-th sort-key="Visitors_Paid">Visitors(Paid)</vs-th>
            <vs-th sort-key="Visitors_Free">Visitors(Free)</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.Reseller">{{ tr.Reseller }} </vs-td>
              <vs-td :data="tr.Customer">{{ tr.Customer }} </vs-td>
              <vs-td :data="tr.Plan_Type">{{ tr.Plan_Type }} </vs-td>
              <vs-td :data="tr.Cookie_Banner">{{ tr.Cookie_Banner }} </vs-td>
              <vs-td :data="tr.Max_Identifications">{{ tr.Max_Identifications }} </vs-td>
              <vs-td :data="tr.Cost_Per_Identifications">
                <span>$</span>{{ tr.Cost_Per_Identifications.toFixed(2) }} 
              </vs-td>
              <vs-td :data="tr.Visitors_Paid">{{ tr.Visitors_Paid }} </vs-td>
              <vs-td :data="tr.Visitors_Free">{{ tr.Visitors_Free }} </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>
<script>
import VueJwtDecode from "vue-jwt-decode";
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import DateRangePicker from "../../components/filter/DateRangePicker";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    VxCard,
    vSelect,
    DateRangePicker,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      excelFields: {
        "Reseller": "Reseller",
        "Customer": "Customer",
        "Plan Type": "Plan_Type",
        "Cookie Banner": "Cookie_Banner",
        "Max": "Max_Identifications",
        "Unit Cost": "Cost_Per_Identifications",
        "Visitors(Paid)": "Visitors_Paid",
        "Visitors(Free)": "Visitors_Free"
      },
      excelData: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      uid: this.$route.params.id,
      initialRole:'',
      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
      consumerResellerDetailReportData: [],
      monthYearData: [],
      monthYearReport: "",
      isOnLoad: true
    };
  },
  mounted() {
    let jwtDecode = VueJwtDecode.decode(
      this.$store.state.authTokenVV[this.uid].token
    );
    this.initialRole = jwtDecode.initialRole;

    var menuitem = document.getElementsByClassName("vs-sidebar-group ");
    //This is usefull to stay open Reseller menu and selected Reseller Reports
    menuitem.forEach(element => {
      var arrow = element.getElementsByClassName(
        "feather-icon select-none relative feather-grp-header-arrow"
      );
      var text = element.getElementsByClassName("truncate mr-3 select-none")[0]
        .innerText;
      var uls = element.getElementsByTagName("ul");
      if (text == "Reseller") {
        element.className += " vs-sidebar-group-open";
        arrow[0].className += " rotate90";
        uls[0].style.maxHeight = "none";

        uls.forEach(elementInner => {
          var lis = elementInner.getElementsByTagName("li");
          lis.forEach(elementInnerInner => {
            var textInner = elementInnerInner.innerText;
            var a = elementInnerInner.getElementsByTagName("a")
            if(textInner == 'Reseller Reports'){
              a[0].className += " router-link-exact-active router-link-active";
            }
          })  
        })
      }
    });

    this.getConsumerResellerDetailReport();
    this.getHistoricReportMonthList();
  },
  //This is usefull to close Reseller menu and unselected Reseller Reports when leave the menu
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      let menuitem = [];
      menuitem = document.getElementsByClassName("vs-sidebar-group ");
      menuitem.forEach(element => {
        if (element.classList.contains("vs-sidebar-group-open")) {
          let text = element.getElementsByClassName(
              "truncate mr-3 select-none"
          )[0].innerText;
          let uls = element.getElementsByTagName("ul");
          let arrow = element.getElementsByClassName(
              "feather-icon select-none relative feather-grp-header-arrow"
          );
          let det = to.path;
          det = det.match("/reseller");
          if (text == "Reseller" && !det) {
            uls[0].style.maxHeight = 0;
            arrow[0].classList.remove("rotate90");
            element.classList.remove("vs-sidebar-group-open");

            uls.forEach(elementInner => {
              var lis = elementInner.getElementsByTagName("li");
              lis.forEach(elementInnerInner => {
                var textInner = elementInnerInner.innerText;
                var a = elementInnerInner.getElementsByTagName("a")
                if(textInner == 'Reseller Reports'){
                  a[0].className = "";
                }
              })  
            })
          }
        }
      });
    }, 300);
    next();
  },
  methods: {
    filterExport(search = false) {
      let data = [];
      let consumerResellerDetailReportDataLocal = !search
        ? this.consumerResellerDetailReportData
        : this.$refs.tableData.datax;

      for (var i = 0; i < consumerResellerDetailReportDataLocal.length; i++) {
        data.push({
          Reseller: consumerResellerDetailReportDataLocal[i].Reseller,
          Customer: consumerResellerDetailReportDataLocal[i].Customer,
          Plan_Type: consumerResellerDetailReportDataLocal[i].Plan_Type,
          Cookie_Banner: consumerResellerDetailReportDataLocal[i].Cookie_Banner,
          Max_Identifications: consumerResellerDetailReportDataLocal[i].Max_Identifications,
          Cost_Per_Identifications: "$"+ consumerResellerDetailReportDataLocal[i].Cost_Per_Identifications.toFixed(2),
          Visitors_Paid: consumerResellerDetailReportDataLocal[i].Visitors_Paid,
          Visitors_Free: consumerResellerDetailReportDataLocal[i].Visitors_Free
        });
      }
      return data;
    },
    getConsumerResellerDetailReport(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerReporting/GetConsumerResellerDetailReport?datelookup="+this.monthYearReport).then((response) => {
        let data = response.data;
        this.consumerResellerDetailReportData=data;

        let that = this;
        //Filter Location Breakdown datatable record based on search parameter for excel
        setTimeout(() => {
          document.querySelector("#consumerResellerDetailReport .input-search")
            .addEventListener("keyup", function() {
              that.excelData = that.filterExport(true);
            });
        }, 1000);
        this.excelData = this.filterExport();
        if(this.isOnLoad){
          this.isOnLoad = false
          this.getHistoricReportMonthList()
        }

        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getHistoricReportMonthList(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerReporting/GetHistoricReportMonthList").then((response) => {
        let data = response.data;
        this.monthYearData=data;
        this.monthYearReport=data[0].Key
        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    }
  }
};
</script>
